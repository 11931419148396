import { Injectable } from '@angular/core';
import { messages } from './messages';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  /**
   * Get the display message for the given key.
   * @param key the message key
   * @returns the translated message or the message itself
   */
  getMessage(key: string): string {
    return messages[key] || key;
  }
}
